/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import tech_pioneer from '../../assets/tech-pioneer.png'
import { Link } from 'react-router-dom';

const TechnologyPioneer = () => {
  return (
    <div className='flex justify-center items-center w-full'>
      <div className='flex sm:flex-row flex-col justify-center items-center w-full sm:py-0 py-5 lg:px-3 px-3 mt-5 pb-16 max-w-7xl'>

        <div className=' flex justify-center items-center sm:w-1/2 w-full lg:h-[40rem]'>
          <img src={tech_pioneer} alt="logo" className='lg:h-[29rem] object-cover'
          />
        </div>

        <div className='sm:w-1/2 w-full px-3 font-Poppins sm:mt-0 mt-2 bg-cover bg-center bg-no-repeat'
          // style={{ backgroundImage: `url(${back_1})` }}
        >
          <h1 className='text-orange-500 font-mono'>TECHNOLOGY PIONEERS</h1>
          <h2 className='lg:py-10 py-5 text-blue-950 font-bold text-2xl'>Unveiling the IMO-Tech Solutions Experience</h2>
          <p className='pb-10 text-gray-700 font-semibold text-xl'>
            Embark on a Journey of Technological <br/> Ingenuity and Innovation with IMO-Tech
          </p>

          <p className='text-lg text-gray-700 font-Archivo max-w-[30rem] '>
            Founded in 2022, IMO-Tech emerged from a shared vision among pioneers determined to redefine the
            digital landscape. Today, we have evolved into a powerhouse of creativity and skill, offering an
            extensive array of services that empower businesses and individuals in the ever-evolving digital frontier.
          </p>

          <div className='flex flex-row lg:gap-6 gap-4 w-full mt-5 '>
            <Link
              to='/contact_us'
              className='bg-orange-500 text-white text-center w-full py-2 rounded hover:bg-orange-600'
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      </div>
  );
}

export default TechnologyPioneer;
