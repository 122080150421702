/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

// Import all partner logos
import port from '../../images/partners/port.png';
import mellon from '../../images/partners/mellon.png';
import aws from '../../images/partners/aws.png';
import comptia from '../../images/partners/comptia.png';
import oracle from '../../images/partners/oracle.png';
import microsoft from '../../images/partners/microsoft.png';

// Partner logos array
const partners = [
  { id: 1, name: 'Port', image: port },
  { id: 2, name: 'Mellon', image: mellon },
  { id: 3, name: 'AWS', image: aws },
  { id: 4, name: 'CompTIA', image: comptia },
  { id: 5, name: 'Oracle', image: oracle },
  { id: 6, name: 'Microsoft', image: microsoft },
];

const TrustedBy = () => {
  return (
    <div>
      <div className="container my-24 mx-auto md:px-6">
        <section className="mb-32 text-center">
          <h2 className="lg:pb-10 py-5 text-blue-950 font-bold text-2xl">
            Trusted by over 200 users and companies
          </h2>

          {/* Dynamic Partner Grid */}
          <div className="grid px-6 md:grid-cols-4 lg:grid-cols-6 grid-cols-2 gap-4">
            {partners.map((partner) => (
              <div key={partner.id} className="mx-auto mb-12 lg:mb-0">
                <img
                  src={partner.image}
                  alt={`${partner.name} logo`}
                  className="max-w-[90px] dark:brightness-150"
                />
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default TrustedBy;
