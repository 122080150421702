import React, { useState } from 'react';
import Lottie from 'lottie-react'

import graphic_design from '../../lottie/graphic_design.json'
import web_design from '../../lottie/web_design.json'
import ux_ui from '../../lottie/ux_ui.json'
import sco from '../../lottie/sco.json'
import digital_marketing from '../../lottie/digital_marketing.json'
import consultancy from '../../lottie/consultancy.json'
import government_services from '../../lottie/government_services.json'
import managed_services from '../../lottie/managed_service.json'
import cloud_solutions from '../../lottie/cloud_solutions.json'
import cybersecurity from '../../lottie/cybersecurity.json'
import procurement from '../../lottie/procurement.json'


import exploration from '../../assets/continuous_exploration.png'
import experiments from '../../assets/iterative_experiments.png'
import implement from '../../assets/collaborative_implement.png'




interface Service {
    imageFile: any,
    title: string;
    subtitle: string;
    description: string;
    // features: { title: string; description: string; imageSrc: string }[];
  }

  const services: Service[] = [
    {
      imageFile: consultancy,
      title: 'IT CONSULTANCY',
      subtitle: 'Reliable and Scalable IT Solutions for Business Needs',
      description: `Our experienced team collaborates with businesses to analyze their technology needs, designing tailored, scalable solutions. We emphasize innovation, efficiency, and growth by offering strategic consultations and hands-on implementation of advanced IT frameworks to streamline operations and drive success.`,
      // features: [
      //   { title: 'Client-Centric Approach', description: 'We customize solutions that address your specific business challenges.', imageSrc: exploration },
      //   { title: 'Scalable Solutions', description: 'Future-proof services designed to scale with your company’s growth.', imageSrc: experiments },
      //   { title: 'Expert Guidance', description: 'Get actionable insights and strategic recommendations from industry professionals.', imageSrc: implement },
      // ],
    },
    {
      imageFile: government_services,
      title: 'GOVERNMENT SERVICES',
      subtitle: 'Expertise in Serving Government Agencies',
      description: `We bring years of experience supporting government operations with secure, efficient IT solutions. Our services include advanced cybersecurity, compliance adherence, and seamless system integrations, ensuring agencies meet their goals while maintaining high standards of security and transparency.`,
      // features: [
      //   { title: 'Broad Experience', description: 'Proven success with major agencies including VA, DOD, DHS, and USPS.', imageSrc: exploration },
      //   { title: 'Security Focused', description: 'Adherence to federal compliance standards with robust cybersecurity measures.', imageSrc: experiments },
      //   { title: 'Efficient Processes', description: 'Automation-driven processes that optimize workflow efficiency.', imageSrc: implement },
      // ],
    },
    {
      imageFile: managed_services,
      title: 'MANAGED IT SERVICES',
      subtitle: 'Flexible IT Support Programs for Business Needs',
      description: `Our managed IT services are designed to ensure uninterrupted business operations. With 24/7 proactive monitoring and fast response times, we handle your IT infrastructure so you can focus on growing your business.`,
      // features: [
      //   { title: '24/7 Support', description: 'Technical support anytime you need it.', imageSrc: exploration },
      //   { title: 'Proactive Monitoring', description: 'We resolve issues before they become disruptions.', imageSrc: experiments },
      //   { title: 'Customized Plans', description: 'Flexible service models tailored to your specific needs.', imageSrc: implement },
      // ],
    },
    {
      imageFile: cloud_solutions,
      title: 'CLOUD SOLUTIONS',
      subtitle: 'Comprehensive Managed Cloud Services',
      description: `IMO Tech offers secure, scalable cloud services tailored for modern businesses. Our solutions range from data storage to application hosting, ensuring high performance and security while reducing overhead costs.`,
      // features: [
      //   { title: 'Secure Storage', description: 'Enterprise-grade security with encrypted cloud storage.', imageSrc: exploration },
      //   { title: 'Data Management', description: 'Efficient management and processing of your data.', imageSrc: experiments },
      //   { title: 'Application Hosting', description: 'Seamless application hosting on reliable cloud servers.', imageSrc: implement },
      // ],
    },
    {
      imageFile: cybersecurity,
      title: 'CYBER SECURITY',
      subtitle: 'Protecting Your Business from Digital Threats',
      description: `Our cybersecurity solutions safeguard your business from evolving digital threats. We implement multi-layered security protocols, perform regular vulnerability assessments, and ensure data privacy and compliance with global standards.`,
      // features: [
      //   { title: 'Threat Analysis', description: 'We detect vulnerabilities through advanced analytics.', imageSrc: exploration },
      //   { title: 'Data Protection', description: 'Our encryption measures protect sensitive data.', imageSrc: experiments },
      //   { title: 'Incident Response', description: 'Our rapid response protocols mitigate damage from attacks.', imageSrc: implement },
      // ],
    },
    {
      imageFile: procurement,
      title: 'IT PRODUCTS PROCUREMENT',
      subtitle: 'Flexible IT Support and Product Supply',
      description: `We simplify IT procurement with a wide range of technology solutions from leading vendors. From hardware to software, we ensure cost-effective purchases that align with your business requirements.`,
      // features: [
      //   { title: 'Wide Range of Products', description: 'Choose from top-tier technology solutions.', imageSrc: exploration },
      //   { title: 'Vendor Management', description: 'We maintain relationships with trusted suppliers for timely delivery.', imageSrc: experiments },
      //   { title: 'Cost Efficiency', description: 'We offer solutions that maximize value and minimize cost.', imageSrc: implement },
      // ],
    },
    {
      imageFile: graphic_design,
      title: 'STRATEGIC CREATIVITY',
      subtitle: 'Graphic Designing Excellence Redefined',
      description: `
            Step into IMO-Tech’s Graphic Designing realm, where innovative thinking meets strategic creativity.
            Our team crafts compelling designs, from logos to marketing collateral, enhancing your brand identity.
            Collaborate with us through a process involving consultation, iterative refinement, and timely delivery.
            We prioritize a strategic approach, merging creativity with intent to tell a powerful story through designs.
            Elevate your brand visuals and market presence with our efficient and collaborative Graphic Designing services.
            Contact IMO-Tech to transform your ideas into visually compelling realities.`,
      // features: [
      //   {
      //     title: 'Strategic Creativity',
      //     description: 'Our designs blend creativity with strategic intent, ensuring visual elements align with your brand\'s narrative and goals.',
      //     imageSrc: exploration,
      //   },
      //   {
      //     title: 'Comprehensive Solutions',
      //     description: 'From logos to marketing collateral, our graphic designing services cover a spectrum of visual communication needs.',
      //     imageSrc: experiments,
      //   },
      //   {
      //     title: 'Brand Identity Enhancement',
      //     description: 'Elevate your brand visuals and create a distinct identity in the market with our customized graphic solutions.',
      //     imageSrc: implement,
      //   },
      // ],
    },

    {
        imageFile: web_design,
        title: 'WEB DESIGN EXCELLENCE',
        subtitle: 'Crafting Digital Experiences Beyond Imagination with Web Technology',
        description: `
            Embark on a journey of digital transformation with IMO-Tech’s unparalleled web design services.
            Our expert team is dedicated to creating immersive and user-centric websites that captivate audiences and
            drive results. Collaborate with us to redefine your online presence through innovative design, seamless
            functionality, and intuitive navigation. We specialize in crafting responsive and visually stunning websites
            tailored to your brand's unique identity and objectives. From concept to deployment, we prioritize excellence,
            ensuring your website stands out in today's competitive digital landscape. Elevate your online success with
            IMO-Tech cutting-edge web design solutions. Contact us now to embark on your digital journey.`,
        // features: [
        //   {
        //     title: 'Innovative Design',
        //     description: `Our web designs push boundaries, incorporating the  latest trends and technologies to deliver
        //                     visually striking and memorable digital experiences.`,
        //     imageSrc: exploration,
        //   },
        //   {
        //     title: 'User-Centric Approach',
        //     description: `We prioritize user experience, ensuring intuitive navigation and seamless functionality across
        //         devices to engage and delight your audience.`,
        //     imageSrc: experiments,
        //   },
        //   {
        //     title: 'Responsive Solutions',
        //     description: `With a focus on responsiveness, our websites adapt seamlessly to various screen sizes,
        //         providing an optimal viewing experience for users on desktops, tablets, and mobile devices.`,
        //     imageSrc: implement,
        //   },
        // ],
      },

      {
        imageFile: ux_ui,
        title: 'ENGAGING SOLUTIONS',
        subtitle: 'Elevate Experiences with UI-UX Design',
        description: `
            Welcome to IMO-Tech's UI-UX Design hub, where creativity meets functionality to ensure seamless and
            delightful user experiences. Our dedicated team specializes in crafting interfaces that go beyond aesthetics,
            prioritizing intuitive design for a user-centric approach.`,
        // features: [
        //   {
        //     title: 'Intuitive Design',
        //     description: `Our focus is on creating interfaces that users can navigate effortlessly, ensuring a positive
        //         and engaging experience.`,
        //     imageSrc: exploration,
        //   },
        //   {
        //     title: 'Responsive Solutions',
        //     description: `Our UI-UX designs are responsive across devices, providing a consistent and enjoyable experience.`,
        //     imageSrc: experiments,
        //   },
        //   {
        //     title: 'User-Centric Approach',
        //     description: `We prioritize the needs and preferences of the end-user, ensuring every interaction is natural
        //         and purposeful.`,
        //     imageSrc: implement,
        //   },
        // ],
      },

      {
        imageFile: sco,
        title: 'STRATEGIC OPTIMIZATION',
        subtitle: 'SCO. Boost Visibility with Expert Search Engine Optimization Services',
        description: `
            Welcome to IMO-Tech’s Search Engine Optimization (SEO) expertise, where we redefine your digital presence
            by strategically optimizing your website. Our dedicated team specializes in search engine optimization
            techniques that elevate your rankings, increase organic traffic, and ensure your brand stands out in the
            competitive online landscape.`,
        // features: [
        //   {
        //     title: 'On-Page SEO',
        //     description: `Optimize your website's structure, content, and meta tags for search engine visibility and user experience.`,
        //     imageSrc: exploration,
        //   },
        //   {
        //     title: 'Off-Page SEO',
        //     description: `Build high-quality backlinks, social signals, and online presence to enhance your website's
        //         authority and credibility.`,
        //     imageSrc: experiments,
        //   },
        //   {
        //     title: 'Keyword Research',
        //     description: `Conduct in-depth keyword research to target relevant search terms and improve your website's
        //         visibility for your target audience.`,
        //     imageSrc: implement,
        //   },
        // ],
      },

      {
        imageFile: digital_marketing,
        title: 'COMPREHENSIVE DIGITAL MARKETING SOLUTIONS"',
        subtitle: 'Elevate Your Online Presence with Expert Digital Marketing Strategies',
        description: `
            Welcome to IMO-Tech’s comprehensive digital marketing solutions, where we empower your brand to thrive in
            the dynamic online landscape. Our team of digital marketing experts crafts tailored strategies to enhance
            your brand visibility, engage your target audience, and drive measurable results. From search engine
            optimization (SEO) and social media marketing to content creation and paid advertising, we offer a holistic
            approach to maximize your online success. Collaborate with us to leverage cutting-edge digital marketing
            techniques and stay ahead of the competition.`,
        // features: [
        //   {
        //     title: 'Search Engine Optimization (SEO)',
        //     description: `Optimize your website to rank higher in search engine results pages (SERPs) and attract
        //     organic traffic through strategic keyword targeting and technical optimization.`,
        //     imageSrc: exploration,
        //   },
        //   {
        //     title: 'Social Media Marketing',
        //     description: `Engage with your audience across various social media platforms, build brand awareness,
        //     and drive conversions through targeted content creation, community management, and advertising campaigns.`,
        //     imageSrc: experiments,
        //   },
        //   {
        //     title: 'Content Marketing',
        //     description: `Create valuable and relevant content to attract, educate, and engage your audience,
        //         establishing your brand as an authority in your industry and driving long-term customer loyalty.`,
        //     imageSrc: implement,
        //   },
        // ],
      },



    // Add more services as needed
  ];

  const AllServices: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');

    const filteredServices = services.filter(service =>
      service.subtitle.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <div className="flex flex-col justify-center items-center w-full mt-10">

        <div className='flex justify-center items-center w-full sm:px-0 px-4'>
            <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded px-3 py-1 mb-4 sm:w-1/3 w-full"
            />
        </div>



        {filteredServices.map((service, index) => (
          <div key={index} className="flex sm:flex-row flex-col w-full max-w-7xl py-7">
            <div className="sm:w-[30rem] sm:px-3 px-8">
                <Lottie animationData={service.imageFile} width={200} height={200} loop={true} className='w-[13rem]' />
            </div>

            <div className="w-full px-4 sm:border-x">
              <h1 className="text-orange-500 font-mono lg:mt-2 mt-10">{service.title}</h1>
              <h2 className="lg:pt-5 py-1 text-blue-950 font-bold text-2xl mb-6">{service.subtitle}</h2>

              <p className="text-sm text-gray-700 font-Archivo max-w-[30rem]">{service.description}</p>
            </div>

            {/* <div className="px-3">
              <h2 className="lg:pt-5 py-1 text-blue-950 font-bold text-lg mb-2 sm:mt-0 mt-2">Key Features:</h2>
              {service.features.map((feature, index) => (
                <div key={index}>
                  <img src={feature.imageSrc} alt="graphic" className="w-[4rem] object-cover" />
                  <h2 className="lg:pt-2 pt-1 text-blue-950 font-bold text-sm mb-2">{feature.title}</h2>
                  <p className="text-xs text-gray-700 font-Archivo sm:max-w-[35rem]">{feature.description}</p>
                </div>
              ))}
            </div> */}
          </div>
        ))}
      </div>
    );
  };

  export default AllServices;
