import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import kenecare_doctor from '../../images/projects/kenecare_doctor.jpg';
import qrl_digicard from '../../images/projects/qrl_digicard.png';
import asset from '../../images/projects/asset.png';
import attendance from '../../images/projects/attendance.png';

interface Project {
  link: any;
  id: number;
  title: string;
  description: string; // Short description
  fullDescription: string; // Full detailed description
  imageUrl: string;
}

const projectData: Project[] = [
  {
    link: 'https://www.kenecare.com',
    id: 1,
    title: 'Kenecare',
    description: 'A platform for healthcare professionals.',
    fullDescription: 'Kenecare is a comprehensive platform designed to connect healthcare professionals with patients. It offers features like appointment scheduling, telemedicine, and patient management. Built with scalability in mind, it supports thousands of users simultaneously.',
    imageUrl: kenecare_doctor,
  },
  {
    link: 'https://digicardsl.com',
    id: 2,
    title: 'DigiCard Business Card',
    description: 'Digital business cards for modern professionals.',
    fullDescription: 'DigiCard is a digital business card solution that allows professionals to share their contact information seamlessly. It supports QR codes, NFC technology, and integrates with CRM systems for easy contact management.',
    imageUrl: qrl_digicard,
  },
  {
    link: '#',
    id: 3,
    title: 'Attendance Management System',
    description: 'Efficiently track and manage attendance.',
    fullDescription: 'This system is designed to automate attendance tracking for employees, students, or staff. It includes features like biometric integration, real-time reporting, and customizable attendance policies.',
    imageUrl: attendance,
  },
  {
    link: '#',
    id: 4,
    title: 'Asset Management System',
    description: 'Manage and upgrade assets cost-effectively.',
    fullDescription: 'The Asset Management System helps organizations maintain and upgrade their assets efficiently. It includes features like asset tracking, maintenance scheduling, and cost analysis to ensure optimal asset utilization.',
    imageUrl: asset,
  },
  // Add more project objects as needed
];

const Projects: React.FC = () => {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProjectClick = (project: Project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  return (
    <div>
      <div className="flex justify-center items-center w-full">
        <section className="grid lg:grid-cols-3 sm:grid-cols-2 grid-flow-row mb-32 gap-3 text-center md:text-left py-10 px-3 max-w-7xl">
          {projectData.map((project) => (
            <div
              key={project.id}
              onClick={() => handleProjectClick(project)}
              className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] lg:w-[25rem] sm:w-auto bounce-on-hover cursor-pointer"
            >
              <div className="flex flex-wrap items-center">
                <div className="block w-full shrink-0 grow-0 basis-auto md:w-4/12 lg:flex">
                  <img
                    src={project.imageUrl}
                    alt={project.title}
                    className="w-full sm:h-full h-[25rem] rounded-t-lg sm:rounded-tr-none sm:rounded-bl-lg"
                  />
                </div>
                <div className="w-full shrink-0 grow-0 basis-auto md:w-8/12">
                  <div className="px-6 py-5 sm:px-4">
                    <h2 className="mb-3 text-xl font-semibold font-Poppins">
                      <span className="text-blue-600">{project.title}</span>
                    </h2>
                    <p className="pb-2 text-gray-600 text-xs font-Poppins">{project.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>

      {isModalOpen && selectedProject && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center backdrop-blur-lg">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">{selectedProject.title}</h2>
              <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                &times;
              </button>
            </div>
            <img src={selectedProject.imageUrl} alt={selectedProject.title} className="w-full h-48 object-cover rounded-lg mb-4" />
            <p className="text-gray-600 mb-4">{selectedProject.description}</p>
            <div className="mb-4">
              <h3 className="font-semibold text-lg mb-2">Full Details</h3>
              <p className="text-gray-600 text-sm">{selectedProject.fullDescription}</p>
            </div>
            <div className="flex justify-end space-x-4">
              <Link to="/contact_us" className="bg-blue-600 text-white px-4 py-2 rounded-lg">
                Contact Sales
              </Link>
              {selectedProject.link !== '#' && (
                <a href={selectedProject.link} target="_blank" rel="noreferrer" className="bg-green-600 text-white px-4 py-2 rounded-lg">
                  View Live
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
