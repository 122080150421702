import React, {useEffect} from 'react'

import collaboration from '../../assets/continuous_exploration.png'
import iterative from '../../assets/iterative_experiments.png'
import collaborative from '../../assets/collaborative_implement.png'
import back_1 from '../../assets/back_1.png'

import excellence from '../../assets/excellence.png'
import innovation from '../../assets/innovation.png'
import integrity from '../../assets/integrity.png'

import eke_1 from '../../images/eke_1.png'
import home_img_figure from '../../images/home_img_figure.png'

import AOS from 'aos';
import 'aos/dist/aos.css';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom'


const Card = ({ image, heading, details }) => {
    return (
      <div className="flex flex-col justify-center items-center max-w-sm rounded overflow-hidden m-4">
        <img className="w-20" src={image} alt="Card" />
        <div className="flex flex-col justify-center items-center px-6 py-4">
          <div className="font-bold lg:text-xl text-sm mb-2 text-center text-blue-950">{heading}</div>
          <p className="text-gray-700 lg:text-base text-sm text-center">{details}</p>
        </div>
      </div>
    );
  };

  const Card2 = ({ image, heading, details }) => {
    return (
      <div className="flex flex-col justify-center items-start max-w-sm rounded overflow-hidden m-4 shadow-xl shadow-blue-200 rounded-br-[5rem]  ">
        <img className="w-20 bounce-on-hover" src={image} alt="Card" />
        <div className="flex flex-col justify-center items-start px-6 py-4">
          <div className="font-bold lg:text-xl text-sm mb-2 text-start text-blue-950">{heading}</div>
          <p className="text-gray-700 lg:text-base text-sm text-start">{details}</p>
        </div>
      </div>
    );
  };

const InnovationLifecycle = () => {
  useEffect(() => {
    const initAOS = () => {
      setTimeout(() => {
        AOS.init({
          delay: 400, // Delay before animations start (in milliseconds)
          duration: 1000, // Duration of the animation (in milliseconds)
          once: true, // Repeats animation when scrolling back up
          offset: 100 // Offset (in pixels) from the original trigger point
        });
      }, 1000); // Delay before initializing AOS (in milliseconds)
    };

    initAOS();
  }, []);
  return (
    <div className="flex flex-col items-center w-full bg-gradient-to-t from-blue-50 to-gray-50 pb-10">
        <div className='max-w-7xl'>
           <h1 className='text-center text-blue-950 py-10 font-poppins font-bold sm:text-3xl text-xl'>
            Innovation Lifecycle
           </h1>

           <div className="flex sm:flex-row flex-col justify-center">
            <Card
                image= {collaboration}
                heading="Continuous Exploration"
                details="Embrace ongoing learning and stay updated on industry trends, fostering a culture that
                            encourages team members to explore and discover new technologies."
            />
            <Card
                image={iterative}
                heading="Iterative Experiments"
                details="Test and refine ideas in a controlled environment, leveraging an iterative approach to
                    quickly assess and improve the viability of innovative concepts."
            />
            <Card
                image={collaborative}
                heading="Collaborative Implement"
                details="Collaborative integrate successful experiments into workflows, ensuring effective
                    cross-functional communication and a transition to practical application."
            />
            </div>

            <div className='flex sm:flex-row flex-col justify-center items-center w-full sm:py-0 py-12 lg:px-3 px-3'>
                <div className='relative flex justify-center sm:w-1/2 w-full lg:h-[40rem] h-[37rem]'>
                  <img src={eke_1} alt="sia" className='h-[25rem] object-cover rounded-tl-2xl rounded-br-2xl mt-[6rem]
                    rounded-tr-[6rem] rounded-bl-[6rem] absolute top-0 left-0' style={{ zIndex: 1 }}
                  />
                  <img src={home_img_figure} alt="home_img_figure"
                    className='h-full w-full opacity-50' data-aos="fade-right"
                    style={{ zIndex: 0 }}
                  />
                </div>

                <div className='sm:w-1/2 w-full px-3 font-Poppins sm:mt-0 mt-2'>
                  <h1 className='text-orange-500 font-mono'>ABOUT US</h1>
                  <h2 className='lg:py-10 py-5 text-blue-950 font-bold text-2xl'>Welcome to IMO-Technology</h2>
                  <p className='pb-10 text-gray-700 font-semibold text-xl'>
                    Where innovation meets excellence in software <br/> solutions.
                  </p>

                  <p className='text-lg text-gray-700 font-Archivo max-w-[30rem] '>
                    We are a passionate team of tech enthusiasts dedicated to transforming ideas into
                    impactful digital experiences. At IMO-Tech, we believe in pushing the boundaries
                    of what’s possible, delivering cutting-edge solutions tailored to meet the unique
                    needs of our clients.
                  </p>

                  <Link to='/about'
                    className="relative inline-flex h-12 overflow-hidden rounded-sm p-[1px] focus:outline-none
                    focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 my-10 ">
                    <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-sm px-5 hover:bg-gray-800 bg-gray-50 py-1 text-sm font-medium hover:text-white text-gray-700 backdrop-blur-3xl">
                      More About Us
                    </span>
                  </Link>

                </div>
            </div>

            <div className='w-full flex lg:flex-row flex-col lg:mt-10 mt-0'>
              <div className="lg:w-1/2 w-full grid sm:grid-cols-2 sm:grid-rows-2 lg:px-0 px-4">

                <div className="flex flex-col justify-start">
                  <h1 className='text-start text-blue-950 sm:pt-[10rem]  font-poppins font-bold sm:text-3xl text-xl'>
                  Our Core Values</h1>
                  <p className="text-gray-700 lg:text-base text-sm text-start sm:py-0 py-6">
                    Excellence, integrity, and innovation drive our core values at IMO-Tech,
                    ensuring your success is our top priority.
                  </p>
                </div>

                <Card2
                  image={excellence}
                  heading="Excellence"
                  details="
                    We are committed to delivering excellence in every project, adhering to the highest
                    standards of quality and performance.
                  "
                />
                <Card2
                  image={integrity}
                  heading="Integrity"
                  details="
                    Trust is the foundation of our relationships. We conduct business with honesty,
                    transparency, and a genuine commitment to the success of our clients.
                  "
                />
                 <Card2
                  image={innovation}
                  heading="Innovation"
                  details="Embracing change and staying ahead of the curve, we encourage a culture of innovation that reflects in our solutions."
                />
              </div>

              <div className='lg:w-1/2 bg-cover bg-center bg-no-repeat sm:px-0 px-3 lg:py-0 py-10'
                style={{ backgroundImage: `url(${back_1})` }}
              >
                 <h1 className='text-center text-blue-950 py-10 font-poppins font-bold sm:text-3xl text-xl'>
                  Why Choose IMO-Technology?
                </h1>
                <p className='sm:px-10 text-gray-600 font-Poppins text-xl'>
                  Choose IMO-Tech for unparalleled expertise, innovative solutions,
                  and collaborative partnerships driving your digital success.
                </p>

                <p className='flex flex-row mt-5 ml-7'>
                  <svg className="w-14 h-14 text-blue-900 "
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd"
                    d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z"
                    clip-rule="evenodd"/>
                  </svg>
                  <p className='sm:px-5 text-gray-500 font-Poppins text-lg'>
                    <span className='text-blue-950 font-medium'>Expertise</span> <br/>
                    Our team comprises skilled professionals with diverse backgrounds,
                    ensuring a comprehensive and holistic approach to your projects.
                  </p>
                </p>

                <p className='flex flex-row mt-5 ml-7'>
                  <svg className="w-14 h-14 text-blue-900 "
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd"
                    d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z"
                    clip-rule="evenodd"/>
                  </svg>
                  <p className='sm:px-5 text-gray-500 font-Poppins text-lg'>
                    <span className='text-blue-950 font-medium'>Innovation</span> <br/>
                    We thrive on creativity and continuous exploration, embracing the latest technologies
                    to deliver solutions that stand out in the market.
                  </p>
                </p>

                <p className='flex flex-row mt-5 ml-7'>
                  <svg className="w-14 h-14 text-blue-900 "
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd"
                    d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z"
                    clip-rule="evenodd"/>
                  </svg>
                  <p className='sm:px-5 text-gray-500 font-Poppins text-lg'>
                    <span className='text-blue-950 font-medium'>Collaboration</span> <br/>
                      Your success is our priority. We work closely with you, fostering a collaborative partnership
                      to understand your goals and deliver solutions that exceed expectations.
                  </p>
                </p>

                <div className='ml-16'>
                  <Link  to='/services'
                    className="relative inline-flex h-12 overflow-hidden rounded-sm p-[1px] focus:outline-none
                    focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 my-10 ">
                    <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite]
                      bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center
                    rounded-sm px-5 hover:bg-gray-800 bg-gray-50 py-1 text-sm font-medium hover:text-white
                    text-gray-700 backdrop-blur-3xl">
                      explore our services
                    </span>
                  </Link>
                </div>

              </div>
            </div>

        </div>
    </div>
  )
}

export default InnovationLifecycle
